import { useUserData } from "hooks/app";
import { Avatar, Flex, Text } from "organic";
import colors from "organic/theme/colors";
import React from "react";

export const TAIPrompt = ({ text, attachment }) => {
  const { userData } = useUserData();
  return (
    <Flex width="100%" flex="1" alignItems="flex-start" justifyContent="flex-end">
      <Flex mr="10px">
        {attachment}
        <Flex backgroundColor="white" border={`1px solid ${colors.gray100}`} borderRadius="12px" px="1rem" py="0.5rem">
          <Text width="252px" font="xsRegular" color="gray900" fontWeight="400">
            {text}
          </Text>
        </Flex>
        {/* Actions */}
        <Flex></Flex>
      </Flex>
      <Flex>
        <Avatar src={userData.avatarUrl} name={`${userData.firstName}`} />
      </Flex>
    </Flex>
  );
};

TAIPrompt.defaultProps = {
  value: `Note: This is just an example of a simple HTML form. In a real-world scenario, you would also want to include
          proper validation and handling of the form data on the server side.`,
  attachment: <></>,
};
