import { apiConstants } from "constants/apiConstants";
import { axiosService } from "services/fetchService";
import { getApiUrl } from "utils";

export default class FarmerPayment_API {
  static initiaiteFarmerPayment = async values => {
    const url = getApiUrl({ endpointName: apiConstants.endpointNames.farmerPayment, path: "/payments/initiate" });
    return axiosService({ method: "POST", url, data: values });
  };
}
