import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { classnames } from "utils";
import { Header, Sidebar } from "components/layout";
import { getDashboardState, getSidebarExpanded, setCurrentPageTabId } from "features/v1/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { LoadingState } from "components/appState";
import { APIProvider } from "@vis.gl/react-google-maps";
import { TAI } from "components/AI";

function Dashboard() {
  const location = useLocation();
  const dispatch = useDispatch();
  let sidebarExpanded = useSelector(getSidebarExpanded);
  let dashboardState = useSelector(getDashboardState);

  let dashboardContent__sidebarPanelClasses = classnames("dashboard-content__sidebar-panel", {
    "dashboard-content__sidebar-panel--expanded": sidebarExpanded,
    "dashboard-content__sidebar-panel--collapsed": !sidebarExpanded,
  });
  let dashboardContent__mainPanelClasses = classnames("dashboard-content__main-panel", {
    "dashboard-content__main-panel--expanded": sidebarExpanded,
    "dashboard-content__main-panel--collapsed": !sidebarExpanded,
  });

  React.useEffect(() => {
    if (location?.state?.tabId) {
      dispatch(setCurrentPageTabId(location?.state?.tabId));
    } else {
      dispatch(setCurrentPageTabId(""));
    }
  }, [location]);

  React.useEffect(() => {
    console.log({ dashboardState });
  }, [dashboardState]);

  return (
    <div className="dashboard-layout">
      {/*  header */}
      <div className="dashboard-layout__header-container">
        <Header />
      </div>
      {/*  content */}
      <div className="dashboard-layout__content-container">
        <div className="dashboard-content">
          {/* content aside */}
          <aside className={dashboardContent__sidebarPanelClasses}>
            <Sidebar />
          </aside>
          {/* content main */}
          <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["marker"]}>
            <main className={dashboardContent__mainPanelClasses}>
              <LoadingState />
              <Outlet />
            </main>
            {dashboardState.showTAI ? <TAI /> : null}
          </APIProvider>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
