import React from "react";
import styled from "styled-components";
import { appGet } from "utils";

export const customIcons = {
  building02: (
    <path
      d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M3 7C3 8.65685 4.34315 10 6 10C7.65685 10 9 8.65685 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 8.65685 16.3431 10 18 10C19.6569 10 21 8.65685 21 7M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V6.2C21 5.0799 21 4.51984 20.782 4.09202C20.5903 3.71569 20.2843 3.40973 19.908 3.21799C19.4802 3 18.9201 3 17.8 3H6.2C5.0799 3 4.51984 3 4.09202 3.21799C3.71569 3.40973 3.40973 3.71569 3.21799 4.09202C3 4.51984 3 5.07989 3 6.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  fallback: (
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke="#101828"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  thriveAI: (
    <path
      d="M22.5 10.9912C16.4249 10.9912 11.5 6.06634 11.5 -0.00878906L11.4985 0.173116C11.4014 6.16435 6.51438 10.9912 0.5 10.9912C6.57513 10.9912 11.5 15.9161 11.5 21.9912L11.5015 21.8093C11.5986 15.8181 16.4856 10.9912 22.5 10.9912Z"
      fill="white"
    />
  ),
  picture: (
    <path
      d="M5.44361 21.9068H19.8794C21.0184 21.9068 21.9417 20.9835 21.9417 19.8445V5.4087C21.9417 4.26974 21.0184 3.34644 19.8794 3.34644H5.44361C4.30465 3.34644 3.38135 4.26974 3.38135 5.4087V19.8445C3.38135 20.9835 4.30465 21.9068 5.44361 21.9068ZM5.44361 21.9068L16.7861 10.5644L21.9417 15.72M10.5993 9.01766C10.5993 9.87187 9.90679 10.5644 9.05257 10.5644C8.19835 10.5644 7.50587 9.87187 7.50587 9.01766C7.50587 8.16344 8.19835 7.47096 9.05257 7.47096C9.90679 7.47096 10.5993 8.16344 10.5993 9.01766Z"
      stroke="#84C56F"
      strokeWidth="1.76765"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  ),
  thriveAIDouble: (
    <>
      <path
        d="M30.6628 17.2489C23.6207 17.2489 17.912 11.5401 17.912 4.49805L17.9103 4.70891C17.7977 11.6537 12.1328 17.2489 5.16113 17.2489C12.2032 17.2489 17.912 22.9576 17.912 29.9997L17.9137 29.7889C18.0263 22.844 23.6911 17.2489 30.6628 17.2489Z"
        fill="white"
      />
      <path
        d="M11.6011 6.22436C8.39751 6.22436 5.80053 3.62737 5.80053 0.423828L5.79975 0.51975C5.74853 3.67905 3.17151 6.22436 0 6.22436C3.20354 6.22436 5.80053 8.82134 5.80053 12.0249L5.8013 11.929C5.85253 8.76966 8.42955 6.22436 11.6011 6.22436Z"
        fill="white"
      />
    </>
  ),
  thriveAIGenerating: (
    <>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" fill="none" viewBox="0 0 49 49"> */}
      <g clipPath="url(#paint0_angular_11095_2360_clip_path)" data-figma-skip-parse="true">
        <foreignObject
          width="2083.33"
          height="2083.33"
          x="-1041.67"
          y="-1041.67"
          transform="rotate(90 .038 24.462)scale(.024)"
        >
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            opacity="1"
            style={{
              background: "conic-gradient(from 90deg,#c5ebb4 0deg,#a6da8e 127.8deg,#eaffe0 214.2deg,#c5ebb4 360deg)",
              height: "100%",
              width: "100%",
            }}
          ></div>
        </foreignObject>
      </g>
      <path
        d="M24.5 5.165c0-2.619 2.143-4.789 4.71-4.275A24 24 0 1 1 8.494 6.54c1.95-1.746 4.899-.964 6.228 1.291 1.33 2.256.494 5.128-1.216 7.11a14.517 14.517 0 1 0 15.651-4.268c-2.48-.84-4.657-2.89-4.657-5.508"
        data-figma-gradient-fill='{"type":"GRADIENT_ANGULAR","stops":[{"color":{"r":0.65098041296005249,"g":0.85490196943283081,"b":0.55686277151107788,"a":1.0},"position":0.35499998927116394},{"color":{"r":0.91906183958053589,"g":1.0,"b":0.88170570135116577,"a":1.0},"position":0.59500002861022949}],"stopsVar":[{"color":{"r":0.65098041296005249,"g":0.85490196943283081,"b":0.55686277151107788,"a":1.0},"position":0.35499998927116394},{"color":{"r":0.91906183958053589,"g":1.0,"b":0.88170570135116577,"a":1.0},"position":0.59500002861022949}],"transform":{"m00":2.9391524462044029e-15,"m01":-48.0,"m02":48.50,"m10":48.0,"m11":2.9391524462044029e-15,"m12":0.4235839843750},"opacity":1.0,"blendMode":"NORMAL","visible":true}'
      ></path>
      <defs>
        <clipPath id="paint0_angular_11095_2360_clip_path">
          <path d="M24.5 5.165c0-2.619 2.143-4.789 4.71-4.275A24 24 0 1 1 8.494 6.54c1.95-1.746 4.899-.964 6.228 1.291 1.33 2.256.494 5.128-1.216 7.11a14.517 14.517 0 1 0 15.651-4.268c-2.48-.84-4.657-2.89-4.657-5.508"></path>
        </clipPath>
      </defs>
      {/* </svg> */}
    </>
  ),
};

const StyledSVG = styled.svg``;

export const SVG = React.forwardRef(({ children, className, icon, ...restProps }, forwadedRef) => {
  const Paths = appGet(customIcons, `${icon}`, customIcons.fallback);
  return (
    <StyledSVG
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
      ref={forwadedRef}
      {...restProps}
    >
      {Paths}
      {children}
    </StyledSVG>
  );
});

SVG.displayName = "SVG";
