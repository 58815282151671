import { Button, Flex, Icon, Image, Text } from "organic";
import colors from "organic/theme/colors";
import React from "react";
import taiPreview from "assets/icons/tai-preview.svg";
import { useDashboardData } from "hooks";

export const Splashscreen = () => {
  const { toggleTAIActive } = useDashboardData();
  return (
    <>
      <Flex px="1rem" justifyContent="center">
        <Image src={taiPreview} />
      </Flex>
      <Flex flexDirection="column" px="1rem">
        <Text width="350px" font="xlMedium" paddingX="10px" paddingY="5px" color="gray900" fontWeight="500">
          Generate your next big Agricultural Idea with Thrive AI and explore the world of agriculture
        </Text>
        <Text width="350px" font="lgRegular" paddingX="10px" paddingY="5px" color="gray700" fontWeight="400">
          Power up your productivity with our new AI feature
        </Text>
      </Flex>
      <Flex flexDirection="column" px="1rem">
        <Flex alignItems="center">
          <Icon icon="picture" size="1.5rem" />
          <Text font="smRegular" color="gray700" fontWeight="400">
            Generate Agricultural related images
          </Text>
        </Flex>
        <Flex alignItems="center" gap="1rem" height="40px">
          <Icon icon="edit-3" color={colors.primary300} size="1.5rem" />
          <Text font="smRegular" color="gray700" fontWeight="400">
            Generate Agricultural related topics
          </Text>
        </Flex>
      </Flex>
      <Flex px="1rem">
        <Button
          onClick={() => {
            // toast.info("We're working on something special", { toastId: "aiInfo" });
            toggleTAIActive();
          }}
          display="block"
        >
          Try it out
        </Button>
      </Flex>
    </>
  );
};
