export const rqKeys = {
  marketplace: {
    // fetch
    fetchMarketplaceOverview: "marketplace/trade/getTradeOverview",
    fetchOverviewTrades: "marketplace/trade/overview/getAll",
    fetchAllLogistics: "marketplace/logistics/getAll",
    fetchAllTrades: "marketplace/trade/getAll",
    fetchSingleTrade: null,
    fetchSingleTradeByTradeId: "marketplace/trade/getTradeByTradeId",
    fetchTradeOverview: "marketplace/trade/getAllOverviewWithParams",
    fetchAllTradeReconciliations: "marketplace/tradeReconciliation/getAll",
    fetchAllInvoice: "marketplace/trade/invoice/getAllInvoice",
    fetchSingleInvoice: "marketplace/trade/invoice/invoiceId",
    fetchFarmerPayment: "marketplace/trade/farmerPayment",
    fetchFinanceReporting: "marketplace/trade/financialReporting",
    // create
    createLogistics: "marketplace/logistics/create",
    createTrade: "marketplace/trade/create",
    createTradeReconciliation: "marketplace/tradeReconcialiation/create",
    generateInvoice: "marketplace/trade/generateInvoice",
    fetchTradesRecon: "marketplace/trade/getTradesRecon",
    fetchTradesReconById: "marketplace/trade/getTradesReconById",
    fetchTradesReconByTradeId: "marketplace/trade/getTradesReconByTradeId",
    fetchTradesReconOverview: "marketplace/trade/getTradesReconOverview",
    // update
    updateLogistics: "marketplace/logistics/update",
    updateTrade: "marketplace/trade/update",
    updateTradeReconciliation: "marketplace/tradeReconciliation/update",
    toggleStatus: "marketplace/status/toggleStatus",
  },
  farmer: {
    // fetch
    searchFarmerWithParams: "farmer/search",
  },
  agent: {
    // fetch
    fetchAllAgents: "agents/getWithParams",
    searchAgents: "agents/search",
  },
  overview: {
    // fetch
    fetchInsightAnalytics: "overview/getInsightAnalytics",
  },
  onboarding: {
    // fetch
    getFarmerIdRange: "season/getFarmerIdRange",
    fetchAllContractualMembers: "farmerSeason/getAllContractualMembers",
    fetchContractualFarmerPass: "agent/getAgentData",
    searchFarmerWithParams: "farmer/search",

    // update
    changeMemberToContractual: "farmerSeason/changeContractual",
    updateMemberFarmer: "farmerSeason/updateMember",
    uploadImage: "farmers/uploadFarmerProfileImage2",
    // create
    createContractualFarmer: "farmerSeason/createContractual",
  },
  modules: {
    // fetch
    fetchAllManageModules: "activationModules/getAll",
    getAllExceptGroups: "activationModules/except-groups",
    // create
    createModule: "createActivationModules",
    // update
    editModule: "editActivationModules",
    // delete
    deleteModule: "deleteActivationModules",
  },
  inputDistribution: {
    fetchInputDistributionData: "fetchInputDistributionData",
  },
  ai: {
    fetchConversations: "ai-history",
  },
};
