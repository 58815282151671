import { LoaderSpinner } from "components/appState";
import { useDashboardData } from "hooks";
import { useTAIDeepseek } from "hooks/app/TAI";
import { Button, buttonConstants, Flex, Input } from "organic";
import React from "react";

export const TAIInput = () => {
  const { dashboardState, setTAIPrompt, setTAIView, setCurrentTAIConversation } = useDashboardData();
  const { mutate, isLoading, isPending, isError } = useTAIDeepseek();
  const handlePromptChange = e => {
    // if (onChange) onChange?.(val);
    setTAIPrompt?.(e.target.value);
  };

  const handleSend = () => {
    if (dashboardState.currentTAIView !== "conversation") {
      setTAIView("conversation");
    }

    mutate(
      { prompt: dashboardState.currentTAIPrompt },
      {
        onSuccess(data) {
          setCurrentTAIConversation([{ prompt: dashboardState.currentTAIPrompt, response: data }]);
          setTAIPrompt("");
        },
        onError(error) {
          console.log({ error });
        },
      }
    );
  };

  const handlePromptTyped = e => {
    if (e.key === "Enter" && !isPending && !isLoading) {
      handleSend();
    }
  };

  return (
    <Input
      value={dashboardState.currentTAIPrompt}
      onChange={val => handlePromptChange(val)}
      onKeyDown={handlePromptTyped}
      onBlur={handlePromptTyped}
      isReadOnly={isPending}
      isInvalid={isError}
      leftAddon={
        <Flex>
          <Button isDisabled size="xs" variant={buttonConstants.variants.UNSTYLED} iconOnly="mic" iconSize="18px" />
          <Button
            isDisabled
            size="xs"
            variant={buttonConstants.variants.UNSTYLED}
            iconOnly="plus-circle"
            iconSize="18px"
          />
        </Flex>
      }
      rightAddon={
        <Flex>
          {isLoading ? <LoaderSpinner /> : null}

          <Button
            onClick={() => (isPending || isLoading ? null : handleSend())}
            size="xs"
            variant={buttonConstants.variants.UNSTYLED}
            iconOnly="send"
            iconSize="18px"
            isDisabled={isPending || isLoading}
          />
        </Flex>
      }
      position="absolute"
      placeholder="Ask Thrive AI anything"
    />
  );
};
