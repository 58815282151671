import { useDashboardData } from "hooks";
import { Flex, Text } from "organic";
import React from "react";
import styled, { keyframes } from "styled-components";
import { TAIPrompt } from "./TAIPrompt";
import { TAIResponse } from "./TAIResponse";
import taiGenerating from "assets/icons/taiGenerating.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;
const RotatingIcon = styled.img`
  animation: ${rotate} 2s linear infinite;
  width: 50px;
  height: 50px;
`;

export const TAIConversation = () => {
  const { dashboardState } = useDashboardData();

  const currentConversation = React.useMemo(() => {
    return dashboardState.currentTAIConversation;
  }, [dashboardState.currentTAIConversation]);

  return (
    <Flex flexDirection="column" width="100%">
      {currentConversation?.map?.((item, index) => (
        <Flex flexDirection="column" gap="28px" key={index.toString()} width="100%">
          <TAIPrompt text={item.prompt} />
          <TAIResponse
            isActive={index === currentConversation?.length - 1}
            text={JSON.parse(JSON.stringify(item.response))}
          />
        </Flex>
      ))}
      {dashboardState.isTAIGenerating ? (
        <Flex flexDirection="column" width="100%" textAlign="center" justifyContent="center" alignItems="center">
          <RotatingIcon src={taiGenerating} alt="" width="49px" height="49px" />
          <Text>Generating...</Text>
        </Flex>
      ) : null}
    </Flex>
  );
};
