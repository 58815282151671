import { useDashboardData } from "hooks";
import { Button, buttonConstants, Flex, Icon, Text } from "organic";
import colors from "organic/theme/colors";
import React from "react";

export const TAIHeader = () => {
  const { onCloseTAI, setTAIView, dashboardState } = useDashboardData();
  const { isTAIActive } = dashboardState.data;

  const handleClose = () => {
    setTAIView("history");
    onCloseTAI();
  };
  return (
    <>
      {/* header */}
      <Flex
        px="7px"
        height={isTAIActive ? "58px" : "52px"}
        background={isTAIActive ? "linear-gradient(89.82deg, #51AB03 16.01%, #A7DC50 171.8%)" : colors.white}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
        // borderRadius="8px 0px 0px 8px"
      >
        {/* Icon with text */}
        <Flex ml="1rem" height="52px" alignItems="center">
          {/* Icon with background */}
          <Flex
            backgroundColor="primary500"
            size="36px"
            alignItems="center"
            justifyContent="center"
            borderRadius={isTAIActive ? "none" : "6px"}
            mr="10px"
          >
            {isTAIActive ? <Icon icon={"thriveAIDouble"} size={33} /> : <Icon icon={"thriveAI"} size={32} />}
          </Flex>
          <Text color={isTAIActive ? "white" : "black"}>Thrive AI</Text>
        </Flex>
        {/* close button */}
        <Button colorScheme={buttonConstants.colorSchemes.LINK_GRAY} onClick={handleClose}>
          <Icon icon="x" size="24px" color={isTAIActive ? colors.white : colors.gray800} />
        </Button>
      </Flex>
    </>
  );
};
